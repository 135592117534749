.App {
  text-align: center;
}

.Left-Alligned-Block {
	text-align: left;
}

.App-header {
  min-height: 5vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.container-fluid {
	background-color: var(--bs-gray-500);
	border: 2px solid;
	border-color: var(--bs-blue);
}

.row {
	background-color: var(--bs-white);
	display: flex;;
}

.row-blog-entry {
	border: 1px solid;
	border-color: var(--bs-white);
}

.row-top {
    text-align: center;
}

.row-main {
	border: 1px solid;
        border-color: var(--bs-green);
        background-color: var(--bs-white);
}

.col {
	background-color: var(--bs-white);
	max-width: 95%;
	float: left;
}

.img-25 {
	max-width: 25%;
}
